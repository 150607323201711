.leftPageContent.collapsed{
    width: 0
}

.leftPageContent.open{
    width: 350px;
}

.rightPageContent.collapsed{
  width: 0;
  padding: 0;

}

.rightPageContent.open{
  width: 350px;
}

@media (max-width: 1024px) {
  .leftPageContent.open {
    z-index:6;
    background:white;
    position: absolute;
    top:0;
    bottom:0;
  }
}

#material-select > .materialItem .userMaterialIconBox,
#material-select > .materialItem .editMaterialIcon,
#material-select > .materialItem .deleteMaterialIcon {
  display: none
}