.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f5f9fa;
}

#viewerContainer {
  text-align: initial;
}

.MuiBox-root {
  scrollbar-width: thin !important;
  scrollbar-color: #e0e0e0 white !important;
}