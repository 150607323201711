.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.scrollArea {
  flex: auto;
  overflow-x: auto;
}

.columnContainer {
  display: flex;
}

.column {
  flex: 1 1 0px;
  margin: 0;
  padding: 0;
  min-width: 0;
}

.column:not(:last-child) {
  margin-right: 0;
}

@media all and (max-width: 1200px) {
  /* CSS rules here for screens lower than 1200px */
  #projectFilterSection {
    margin-left: initial;
    margin-top: 17px;
  }

  #projectFilterSection #project-search-filter-form-input{
    margin-left: 0;
  }
}

@media all and (min-width: 1200px) {
  /* CSS rules here for screens above 1200px */
  #projectFilterSection {
    margin-left: auto
  }
}